import React, { useState, useEffect } from 'react'

const prev_icon = require("../../assets/images/arrow-left.png")
const next_icon = require("../../assets/images/arrow-right.png")

const ItemsGallery = props => {

  const [image, setImage] = useState(0)

  return (
  <div className="div-item-img">
    <a href={props.images[image]} target="_blank" style={{cursor: "zoom-in"}}>
      <img className="item-img" src={props.images[image]} />
    </a>
    {image > 0 &&
      <img src={prev_icon} className="arrow" onClick={() => setImage(parseInt(image) - 1)} />
    }
    {image < props.images.length - 1 &&
      <img src={next_icon} className="arrow arrow-right" onClick={() => setImage(parseInt(image) + 1)} />
    }
    <div className="txt-14px">{image+1}/{props.images.length}</div>
  </div>
)}

export default ItemsGallery
