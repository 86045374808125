/* eslint no-console:0 */
const css = require.context("../../assets/stylesheets")
css.keys().forEach(css)

// const js = require.context("../../assets/javascripts")
// js.keys().forEach(js)

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
