import React, {useState} from 'react'
import axios from 'axios'

import { Editor } from '@tinymce/tinymce-react';

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import { Dashboard } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const RemoveButton = props => (<button onClick={(e) => props.removeInput(props.id, props.index, e)}>X</button>)

const ActiveAdmin = props => {

  const moveTo = (e, id, to) => {
    e.preventDefault()
    axios.get('/admin/update_position/' + id + '/' + to)
    .then(function(){ props.useForceUpdate(window.location.pathname.split('/').reverse()[1]) })
    .catch(function (error) { console.log(error); })

  }

  return(<div>
    {props.id &&
      <div>
      <button onClick={e => moveTo(e, props.id, 'up')} style={{padding: '10px 0px'}}>▲</button>
      <button onClick={e => moveTo(e, props.id, 'down')} style={{padding: '10px 0px'}}>▼</button>
      </div>
    }
    <input type="hidden" name={props.model_name + "[components_attributes]["+props.index+"][id]" } value={props.id} />
    <input type="hidden" name={props.model_name + "[components_attributes]["+props.index+"][name]" } value={props.name} />
    <input type="hidden" name={props.model_name + "[components_attributes]["+props.index+"][props]"} value={JSON.stringify({"content": props.content})} />
  </div>)
}

const Hrvatski = require('@uppy/locales/lib/hr_HR')
const uppy = Uppy({
  locale: Hrvatski,
  meta: {
    id: window.location.pathname.split('/').reverse()[1],
    model_name: window.location.pathname.split('/').reverse()[2]
  },
  restrictions: { maxNumberOfFiles: 50 },
  autoProceed: false,
  allowMultipleUploads: true
})

const csrfToken = document.querySelector('[name=csrf-token]').content
const xhrParams = {
  endpoint: '/admin/upload',
  bundle: true,
  headers: {'X-CSRF-TOKEN': csrfToken}
}
uppy.use(XHRUpload, xhrParams)

export const Title = props => {
  const [content, setContent] = useState(props.content)
  return <table><tbody>
    <tr>
      <td width={100}><ActiveAdmin name="title" model_name={props.model_name} props={props} id={props.id} index={props.index} content={content} setContent={setContent} useForceUpdate={props.useForceUpdate} /></td>
      <td><input type="text" placeholder="Naslov" value={content} style={{fontSize: 30, width: '98%'}} onChange={e => setContent(e.target.value)} /></td>
      <td width={100}><RemoveButton id={props.id} index={props.index} removeInput={props.removeInput} /></td>
    </tr>
  </tbody></table>
}

export const Subtitle = props => {
  const [content, setContent] = useState(props.content)
  return <table><tbody>
    <tr>
      <td width={100}><ActiveAdmin name="subtitle" model_name={props.model_name} props={props} id={props.id} index={props.index} content={content} setContent={setContent} useForceUpdate={props.useForceUpdate} /></td>
      <td><input type="text" placeholder="Podnaslov" style={{width: '98%'}} value={content} onChange={e => setContent(e.target.value)} /></td>
      <td width={100}><RemoveButton id={props.id} index={props.index} removeInput={props.removeInput} /></td>
    </tr>
  </tbody></table>
}

export const Image = props => {
  let images = ''
  if(props.src) {
    images = props.src.map((item, index) =>
      <img key={index} src={item.small.url} height={150} style={{marginRight: 15}} />
    )
  }
  return <table><tbody>
    <tr>
      <td width={100}><ActiveAdmin name="image" model_name={props.model_name} props={props} id={props.id} index={props.index} useForceUpdate={props.useForceUpdate} /></td>
      <td>
        {images}
        {images === '' &&
          <Dashboard
            uppy={uppy}
            width='100%'
            height={300}
            {...props}
          />
        }
      </td>
      <td width={100}><RemoveButton id={props.id} index={props.index} removeInput={props.removeInput} /></td>
    </tr>
  </tbody></table>
}

export const Lead = props => {
  const [content, setContent] = useState(props.content)
  return <table><tbody>
    <tr>
      <td width={100}><ActiveAdmin name="lead" model_name={props.model_name} props={props} id={props.id} index={props.index} content={content} setContent={setContent} useForceUpdate={props.useForceUpdate} /></td>
      <td>
        <textarea style={{width: '98%', height: 100}} name="lead" onChange={e => setContent(e.target.value)} value={content}></textarea>
      </td>
      <td width={100}><RemoveButton id={props.id} index={props.index} removeInput={props.removeInput} /></td>
    </tr>
  </tbody></table>
}

export const Text = props => {
  const [content, setContent] = useState(props.content)
  return <table><tbody>
    <tr>
      <td width={100}><ActiveAdmin name="text" model_name={props.model_name} props={props} id={props.id} index={props.index} content={content} setContent={setContent} useForceUpdate={props.useForceUpdate} /></td>
      <td>
        <Editor
         apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
         initialValue={content}
         init={{
           height: 200,
           menubar: false,
           plugins: [
             'image link code paste lists advlist wordcount help',

           ],
           toolbar:
             'undo redo | cut copy paste | bold italic | bullist numlist | link | code help'
         }}
         onEditorChange={(editorContent) => setContent(editorContent)}
       />
      </td>
      <td width={100}><RemoveButton id={props.id} index={props.index} removeInput={props.removeInput} /></td>
    </tr>
  </tbody></table>
}

// export const List = (props) => {
//   const items = props.items.map((item, index) =>
//     <li key={index}>{item}</li>
//   )
//   return props.ordered ? <ol>{items}</ol> : <ul>{items}</ul>
// }

export const Blockquote = props => {
  const [content, setContent] = useState(props.content)
  return <table><tbody>
    <tr>
      <td width={100}><ActiveAdmin name="blockquote" model_name={props.model_name} props={props} id={props.id} index={props.index} content={content} setContent={setContent} useForceUpdate={props.useForceUpdate} /></td>
      <td>
        <textarea style={{width: '98%', height: 100}} name="blockquote" onChange={e => setContent(e.target.value)} value={content}></textarea>
      </td>
      <td width={100}><RemoveButton id={props.id} index={props.index} removeInput={props.removeInput} /></td>
    </tr>
  </tbody></table>
}

export const Video = props => (
  <div>
    <input type="text" name="video" placeholder="Video" />
    <RemoveButton id={props.id} index={props.index} removeInput={props.removeInput} />
  </div>
)
