import React, {useState} from 'react'
import axios from 'axios'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Url from '@uppy/url'
import { Dashboard } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const Hrvatski = require('@uppy/locales/lib/hr_HR')
const csrfToken = document.querySelector('[name=csrf-token]').content

const uppy = Uppy({
  locale: Hrvatski,
  meta: {
    id: window.location.pathname.split('/').reverse()[1],
    model_name: window.location.pathname.split('/').reverse()[2]
  },
  restrictions: { maxNumberOfFiles: 10 },
  autoProceed: false,
  allowMultipleUploads: true
})

const xhrParams = {
  endpoint: '/admin/upload',
  bundle: true,
  headers: {'X-CSRF-TOKEN': csrfToken}
}

uppy.use(XHRUpload, xhrParams)//.use(Url, {companionUrl: 'http://localhost:3000'})


const deleteImage = (img, index, e) => {
  e.preventDefault()
  const id = img.split("/")[4]
  axios.get('/admin/remove_image?index=' + index + '&id=' + id)
  .then(function(){ location.reload() })
  .catch(function (error) { console.log(error); })
}

const Gallery = props => {
  let images = ''
  if(props.images) {
    images = props.images.map((img, key) =>
      <div key={key} style={{display: 'inline-block', marginBottom: 10}}>
        <button onClick={(e) => deleteImage(img.url, key, e)} style={{position: "absolute", width: 20, borderRadius: "0", padding: 5, marginLeft: 180, fontSize: 18}}>×</button>
        <img src={img.small.url} style={{marginRight: 15}} />
      </div>
    )
  }

  return <div style={{width: '79%', marginLeft: '20%'}}>
    {images}
    <Dashboard
      uppy={uppy}
      width='100%'
      height={300}
      {...props}
    />
   </div>
}
export default Gallery
